import { mutate, query } from "configs/http-config"
import { getAllCrypto, receiveCrypto, sendCrypto } from "./schema"

export const web3Apis = {
    getAllCrypto: () => {
        return query('getAllCrypto', getAllCrypto)
    },

    sendCrypto: (data) => {
        return mutate('sendCrypto', sendCrypto, {...data})
    },

    receiveCrypto: (typeCrypto) => {
        return mutate('receiveCrypto', receiveCrypto, { typeCrypto })
    }
}